<template>
  <div class="health">
    <div class="oItem_box">
      <div class="oItem-t">
        <div class="title">运动打卡</div>
        <div class="date">{{ date }}</div>
      </div>
      <div class="oItem-b">
        <p>上传图片</p>
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.proposalPic1"
          :activityPicture.sync="form.proposalPic"
          :maxCount="3"
          ref="load"
        ></v-upload>
        <p class="upload_text">
          请上传您的运动照片，审核通过后即可获得积分啦，建议单个图片不超过5M。
        </p>
      </div>
    </div>

    <div class="submit" @click="submit">确认提交</div>
  </div>
</template>

<script>
import { postHealthUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "health",
  data() {
    return {
      date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      timer: null,
      id: "",
      form: {
        proposalPic: "",
        proposalContent: "",
      },
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  created() {
    this.id = this.$route.query.id;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
    gloabalCount("", 4, 1);
  },
  methods: {
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        if (!this.form.proposalPic) {
          this.$toast("请上传图片");
          return;
        }
        this.postData();
      }
    },
    change() {},
    postData() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        healthPic: this.form.proposalPic,
        recId: this.id,
      };
      this.$axios.post(`${postHealthUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "healthList",
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.health {
  min-height: 100vh;
  background: #f3f4f9;
  padding: 36px 24px;
  box-sizing: border-box;
  .oItem_box {
    width: 100%;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    overflow: hidden;
    padding: 48px 32px 62px 32px;
    box-sizing: border-box;
    background: #fff;
    .oItem-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
      .date {
        font-size: 28px;
        color: #333333;
      }
    }
    .oItem-c {
      padding: 32px;
      ::v-deep .van-field {
        background: #f7f8fa;
      }
    }
    .oItem-b {
      p {
        padding-top: 30px;
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
      }
      .oItem-b_upload {
        margin: 18px 0 16px;
        ::v-deep .van-uploader__preview {
          border: 2px dashed #eee;
        }
        ::v-deep .van-uploader__preview-image {
          width: 160px !important;
          height: 160px !important;
          box-sizing: border-box;
        }
        ::v-deep .van-uploader__upload {
          width: 160px !important;
          height: 160px !important;
          box-sizing: border-box;
        }
        ::v-deep .van-uploader__upload {
          margin: 0;
        }
      }
      .upload_text {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 34px;
      }
    }
  }

  .submit {
    position: fixed;
    bottom: 56px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    background: #fff;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    font-size: 32px;
    line-height: 66px;
    color: #fff;
    // padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    // padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
<style lang="less">
.wishes {
  .van-radio__label {
    font-size: 30px;
  }
}
</style>
